<template>
  <v-container>
    <v-form v-model="valid">
      <h2>{{ title }}</h2>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Name"
            required
            v-model="logo.name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="logo.id == 0">
        <v-col cols="12">
          <v-file-input
            accept="image/png"
            counter
            label="Logo"
            loading="isLoading"
            prepend-icon="mdi-image"
            outlined
            :show-size="1000"
            @change="onChanged"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" label small>
                {{ text }}
              </v-chip>
              <span
                v-else-if="index === 2"
                class="overline grey--text text--darken-3 mx-2"
              >
                +{{ files.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn outlined class="button" @click="onCancel">
          <v-icon left>mdi-cancel</v-icon>Cancel
        </v-btn>
        <v-btn
          outlined
          class="button"
          :disabled="!valid"
          @click="onSave"
        >
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ApiService from "@/_services/api-service"
import router from "@/router"
import store from "@/store"

export default {
  created() {
    this.get();
  },

  data: () => ({
    files: new FormData(),
    logo: {
      id: 0,
      data: [],
      name: "",
      selected: false,
    },
    fileTypes: ["image/png"],
    title: "Add Logo",
    valid: false,
  }),

  methods: {
    get() {
      if (this.$route.params.id > 0) {
        const url = "logo/" + this.$route.params.id;
        ApiService.get(url, this.getSuccess, this.getError);
      }
    },

    getError(error) {
      const notification = {
        title: "Error getting the logo",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getSuccess(response) {
      this.title = "Edit Logo";
      this.logo = response.data;
    },

    onChanged(logo) {
      if (logo) {
        const index = this.fileTypes.find((f) => f == logo.type);
        if (index) {
          this.files = new FormData();
          this.files.append("file", logo, logo.name);
          this.name = logo.name;
        } else {
          const notification = {
            title: "Add Logo",
            status: "error",
            messages: ["Unsupported file type " + logo.type],
          };
          store.commit("setNotification", notification);
        }
      }
    },

    onCancel() {
      store.commit("setAddElementDisplay", false);
      router.go(-1);
    },

    onSave() {
      let url = "logo";
      if (this.logo.id == 0) {
        this.files.append("name", this.logo.name);
        ApiService.post(url, this.files, this.saveSuccess, this.saveError);
      } else {
        url = url + "/" + this.logo.id;
        ApiService.put(url, this.logo, this.saveSuccess, this.saveError);
      }
    },

    saveSuccess() {
      const notification = {
        title: "Save Logo",
        status: "success",
        messages: [this.logo.title + " saved"],
      };
      store.commit("setNotification", notification);
      router.go(-1);
    },

    saveError(error) {
      const notification = {
        title: "Save Logo",
        error: error,
      };
      store.commit("setResponseError", notification);
    },
  },

  router,

  watch: {},
};
</script>

<style lang="scss" scoped>
.button {
  margin-right: 20px;
}
</style>